import React from 'react';
import {
    MDBRow,
    MDBCol,
    MDBIcon
} from 'mdbreact';
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';

const StatCard = ({ source, number, suffix, color, text, duration }) => {
  return (
    <>
    <MDBCol lg="3" md="6" className="mb-5">
    <MDBRow className="d-flex justify-content-center">
            <img
            style={{maxHeight:'100px'}}
            className="w-auto"
            src={source}
            alt=""
            />
          
        {duration&&
        <CountUp end={number} separator=" " duration={duration} redraw={true} suffix={suffix}>
          {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
              <span ref={countUpRef} className="my-auto countUpSpan weight-700" style={{color:'black'}}>
                  
              </span>
            </VisibilitySensor>
          )}
          </CountUp>
        }
        {!duration&&
          <span className="my-auto countUpSpan weight-700" style={{color:color}}>
            {number+" "+(suffix?suffix:'')}
          </span>
        }
    </MDBRow>
    <MDBCol md="12" sm="8" className="d-flex justify-content-center">
      <span className="my-auto text-center weight-800">
          {text}
      </span>
    </MDBCol>
    </MDBCol>
    </>
  );
};

export default StatCard;
