import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBIcon
} from "mdbreact";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption
} from "@reach/combobox";
import { ReCaptcha } from 'react-recaptcha-google';

import Modal from './SmallModal';
import emailKey from './emailkey';
import emailjs from 'emailjs-com';
import counties from '../assets/dpt.json';

import Logo from './img/LOGO_CARBO_FRANCE.png';

class MessageMailPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      items: [],
      mailData:{
        user_name:'',
        user_email:'',
        city:'',
        message:''
      },
      modal:'error',
      toggle:true,
      verified:false
    }
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  };

  componentDidMount() {
    if (this.captcha) {
      console.log("started")
      this.captcha.reset();
    }
  }
  onLoadRecaptcha() {
    if (this.captcha) {
      this.captcha.reset();
    }
  }
  verifyCallback() {
    console.log("access granted");
    this.setState({verified: true});
  }

  submitHandler(event, template){
    event.preventDefault();
      event.target.className += " was-validated";
      const isFilled = this.filledTest();
      (isFilled&&(
          event.target.className = "needs-validation",
          this.clearInputs(),
          this.setState({toggle: false, modal: "success"}),
          emailjs.sendForm(emailKey.SERVICE_ID, emailKey[template], event.target, emailKey.USER_ID)
          .then((result) => {
            console.log("Message envoyé !", result.text);
          },
          (error) => {
            alert("Une erreur a eu lieu, veuillez réessayer", error.text);
          })
      ));
      (!isFilled&&(
        this.setState({toggle: false, modal: "empty"}),
        this.toggle()
      ));
    };

  clearInputs(){
    this.setState(({mailData}) => ({mailData: {
      ...mailData,
      user_name: '',
      user_email:'',
      city:'',
      message:''
    }}));
  };

  resetForm = () => {
    this.setState({toggle: true, modal: "error"}, () => console.log("Form has been reset"));
  };

  filledTest(){
    let count = 0;
    Object.keys(this.state.mailData).map((key) => {
        if(this.state.mailData[key]) {
          if([key]=="user_email") {
            this.checkEmail()?count+=1:count=count;

          }
          else{count +=1;}
        }
      }
    );
    return (count==Object.keys(this.state.mailData).length?true:false);
  };

  checkEmail(){
    let isTrue = false, docList = document.getElementById('formUserEmail').classList;
    this.state.mailData.user_email.includes('@')?
    (docList.remove('is-invalid'), docList.add('is-valid'),isTrue = true)
    :(docList.remove('is-valid'), docList.add('is-invalid'), isTrue = false);
    return isTrue;
  }

  toggle(){
      console.log(this.state.toggle, this.state.modal);
      this.setState({toggle: !this.state.toggle});
  };

  changeHandler = event => {
    let name = event.target.name, value = event.target.value;
    if (name == "user_email"){this.checkEmail()}
    this.setState(({mailData}) => ({mailData: {
      ...mailData,
      [name]: value
    }}));
  };

  regionChangeHandler = event =>{
    var value = event.target.value;
    this.setState(({mailData}) => ({mailData: {
      ...mailData,
      city: value
    }}));
    var lowered = event.target.value.toLowerCase();
    var newValue = lowered.split(' ').join('-');
    let list = counties.filter(item => item.dep_name.toLowerCase().includes(newValue));
    let slist = counties.filter(item => item.num_dep.includes(newValue));
    let totalList = [...list.slice(0,5), ...slist.slice(0,5)];
    this.setState({
      items: totalList
    });
  }

  regionSelectHandler(e, item){
    var region = item.dep_name+' ('+item.num_dep+')';
    this.setState(({mailData}) => ({mailData: {
      ...mailData,
      city: region
    }}));
  }

  render() {
    return (
      <MDBContainer className="pt-3">
        {!this.state.toggle&&this.state.modal=="success"&&
        <Modal initialModalState={true}
        title="Message envoyé"
        text="Nous vous remercions pour votre demande, nos équipes commerciales vous recontactent rapidement" color="success" resetForm={this.resetForm}/>
        }
        {!this.state.toggle&&this.state.modal=="error"&&
        <Modal initialModalState={true}
        title="Une erreur est survenue"
        text="Veuillez réessayer" color="danger" resetForm={this.resetForm}/>
        }
        {!this.state.toggle&&this.state.modal=="empty"&&
        <Modal initialModalState={true}
        title="Formulaire incomplet"
        text="Veuillez renseigner les champs marqués en rouge" color="warning" resetForm={this.resetForm}/>
        }
        <MDBRow>
        <MDBCol lg="6" sm="12" className="align-items-center">
          <MDBRow
          className="mb-3"
          style={{
            backgroundImage:'url('+Logo+')',
            backgroundRepeat:'no-repeat',
            backgroundSize:'contain',
            backgroundPosition:'center',
            minHeight:'250px',
            maxHeight:'50%'
          }}>
          </MDBRow>
          <MDBRow className="h-25 pb-5">
            <MDBCol className="d-flex justify-content-center">
              <div className="d-inline-block">
              <div>
                <span className="weight-600">Téléphone : </span>&nbsp;03 29 75 88 03
              </div>
              <div>
                <span className="weight-600">Mail : </span>&nbsp;contact@carbofrance.fr
              </div>
              <div>
                <span className="weight-600">Adresse : </span>&nbsp;Ecurey, 55290 Montiers-sur-Saulx
              </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol lg="6" sm="12">
        <form
          className="needs-validation"
          onSubmit={(e) => {
            this.submitHandler(e, 'CONTACT_TEMPLATE_ID')}}
          noValidate
        >
          <MDBCol size="12" className="mx-auto pb-3 bg-white z-depth-1">
          <MDBRow>
            <MDBCol md="12" className="my-4 testChildDiv">
              <input
                value={this.state.mailData.user_name}
                name="user_name"
                onChange={this.changeHandler}
                type="text"
                id="formUserName"
                className={(this.state.mailData.user_name?"form-control label-hopped":"form-control")}
                required
              />
              <label
                htmlFor="formUserName"
                className="grey-text"
              >
                Nom ou raison sociale
              </label>
              {/* <div className="valid-feedback">Looks good!</div> */}
              <div className="invalid-feedback">
                Veuillez entrer votre nom ou raison sociale
              </div>
            </MDBCol>
            <MDBCol md="12" className="my-4 testChildDiv">
              <input
                value={this.state.mailData.user_email}
                onChange={this.changeHandler}
                type="text"
                id="formUserEmail"
                className={(this.state.mailData.user_email?"form-control label-hopped":"form-control")}
                name="user_email"
                required
              />
              <label
                htmlFor="formUserEmail"
                className="grey-text"
              >
                Email
              </label>
              <div className="invalid-feedback">
                Veuillez entrer une adresse mail valide
              </div>
            </MDBCol>
            <MDBCol md="12" className="my-4 testChildDiv">
              <Combobox aria-label="Cities" openOnFocus={true} onSelect={(item) => {}}>
                <ComboboxInput
                  value={this.state.mailData.city}
                  id="formCity"
                  autoComplete="off"
                  onChange={this.regionChangeHandler}
                  className={(this.state.mailData.city?"form-control city-search-input label-hopped":"form-control city-search-input")}
                  name="city"
                  style={{ width: '100%' }}
                  required
                />
                <ComboboxPopover>
                  <ComboboxList>
                    {this.state.items[0] &&
                      this.state.items.map(item =>
                      <ComboboxOption onClick={(e) => {this.regionSelectHandler(e, item)}}
                        value={item.dep_name+" ("+item.num_dep+")"}> 
                      </ComboboxOption>)
                    }
                    {!this.state.items[0] &&
                      <ComboboxOption value="null">
                        Aucun résultat trouvé
                      </ComboboxOption>
                    }
                  </ComboboxList>
                </ComboboxPopover>
                <label
                  htmlFor="formCity"
                  className="grey-text"
                >
                  Département
                </label>
                <div className="invalid-feedback">
                  Veuillez entrer un nom de département
                </div>
              </Combobox>
            </MDBCol>
            <MDBCol md="12" className="mt-4 mb-2 testTextarea">
              <textarea
                value={this.state.mailData.message}
                onChange={this.changeHandler}
                id="formMessage"
                className={(this.state.mailData.message?"form-control label-hopped":"form-control")}
                name="message"
                required
              />
              <label
                htmlFor="formMessage"
                className="grey-text"
              >
                Votre message
              </label>
              <div className="invalid-feedback">
                Veuillez écrire un message
              </div>
            </MDBCol>
          </MDBRow>
          </MDBCol>
          {!this.state.verified&&
          <MDBRow className="d-flex justify-content-center m-2 mt-5">
            <ReCaptcha
                ref={(el) => {this.captcha = el;}}
                size="normal"          
                render="explicit"
                sitekey={emailKey.CAPTCHA_SITE_KEY}
                onloadCallback={this.onLoadRecaptcha}
                verifyCallback={this.verifyCallback}
            />
          </MDBRow>
          }
          {this.state.verified&&
          <MDBRow className="d-flex justify-content-center m-2 mt-5">
            <MDBBtn color="primary" type="submit" className="msgBtn">
              Envoyer
              <MDBIcon far icon="paper-plane" className="ml-1" />
            </MDBBtn>
          </MDBRow>
          }
        </form>
        </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default MessageMailPage;