import React from 'react';
import {
    MDBCol
} from 'mdbreact';

class tailgateOption extends React.Component {
    state = {
        extraCost: 0,
        checked:false
    }

    setExtraCost = () => {
        this.setState({
            extraCost: this.props.quantity<6?50:70,
            checked: !this.state.checked
        }, () => this.props.setExtraCost(this.state.checked, this.state.extraCost))
    }

    render(){
    return (
        <>
            {this.props.quantity>0&&
                <MDBCol md="12" className="d-flex flex-column my-4">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="tailGateOption" name="tailGateOption"
                        onChange={() => this.setExtraCost()} value={this.state.checked?this.state.extraCost:0}/>
                        <label class="custom-control-label" for="tailGateOption">Option de livraison avec hayon : {this.props.quantity<6?50:70}€</label>
                    </div>
                    <div className="temporary-hint grey-text">
                        à cocher si vous ne disposez ni de chariot élévateur ni de quai de chargement
                    </div>
                </MDBCol>
            }
        </>
    )}
}

export default tailgateOption