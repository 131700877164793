import React from 'react';
import {
  MDBCol,
  MDBRow
} from 'mdbreact';
import ReactGA from 'react-ga';
import MessageForm from './MessageForm';
import EstimateForm from './EstimateForm';
import './ContactPage.css';
import ToggleSwitch from '../components/toggleSwitch';

class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true
        };
        this.toggleHidden = this.toggleHidden.bind(this);
    }

    scrollToTop = () => window.scrollTo(0, 0);

    toggleHidden() {
        this.setState({active: !this.state.active});
    }

    componentDidMount(){
        document.title = "Carbo France - Contact";
        this.props.printURL(window.location.pathname);
        ReactGA.pageview(window.location.pathname);
    }

  render() {
    return (
      <div className="overflow-hidden align-items-center"
      style={{marginTop:'74px'}}>
        <MDBRow className="d-flex justify-content-center">
            <MDBCol md="8" className="py-3">
                <MDBRow className="w-100 d-flex justify-content-center my-5 mx-auto">
                    <ToggleSwitch
                    onChange={this.toggleHidden}
                    />
                </MDBRow>
                {this.state.active == true &&
                    <>
                    <EstimateForm></EstimateForm>
                    </>
                }

                {this.state.active == false &&
                    <>
                    <MessageForm></MessageForm>
                    </>
                }
            </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default ContactPage;
