import React from 'react';
import {
  MDBCol,
  MDBRow
} from 'mdbreact';
import ReactGA from 'react-ga';
import './AboutPage.css';
import StatCard from '../components/statCard';

import braises from './img/braises.jpg';

import market from './svg/market.svg';
import bag from './svg/bag.svg';
import market_share from './svg/market_share.svg';
import land from './svg/factory.svg';
import r_and_d from './svg/idea.svg';
import euro from './svg/development.svg';
import certificate from './svg/certificate.svg';
import crowd from './svg/crowd.svg';

import TeamVideo from './video/montage.mp4';

const insideStyles = {
  background: "white",
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)"
};

class AboutPage extends React.Component {
  scrollToTop = () => window.scrollTo(0, 0);

  componentDidMount(){
    document.title = "Carbo France - À propos";
    this.props.printURL(window.location.pathname);
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <div className="overflow-hidden">
        <MDBCol style={{
          width: '100%',
          overflow: 'hidden',
          marginTop: '74px',}}
          className="mx-0 px-0 d-flex justify-content-center align-items-center about-video">
          <video style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%'}}
          autoPlay muted controls={false} loop width="1280px" height="720px">
            <source src={TeamVideo} type='video/mp4' />
            Votre navigateur ne prend pas en charge ce format de vidéo (.mp4)
          </video>
          <div style={{position:'absolute',top:'0',left:'0',background:'#00000001'}}
          className="w-100 h-100">

          </div>
          <div style={{position:'absolute',bottom:'0',left:'0',background:'#ffffff',height:'60px'}}
          className="w-100 line-m">
          </div>
        </MDBCol>
          <section className="text-section">
            <MDBRow className="d-flex justify-content-center pt-5">
            <MDBCol lg="5" sm="12">
                <div className="text-left bigger-text p-5 weight-600">
                    <h1 className="text-black weight-800 text-uppercase">À propos de nous</h1>
                    <p className="paragraph my-5">
                    CARBO France produit et commercialise du charbon végétal à usage
                    des barbecues sous les marques de distributeur des enseignes de grande
                    distribution française, mais aussi à une multitude de clients variés
                    satisfaits de la qualité du charbon de bois <span className="weight-800"> CARBO France</span>. 
                    </p>
                    <p className="paragraph">
                    L’entreprise couvre actuellement une superficie totale de 11 hectares en
                    Meuse, regroupant :
                    </p>
                    <ul>
                      <li className="m-2">
                        le siège social et ses services comptabilité, administration commerciale
                        (achat de matière première et ventes des produits finis) et
                        Qualité-Sécurité-Environnement ;
                      </li>
                      <li className="m-2">
                        le site de production composé des équipements de préparation du bois et
                        de carbonisation (16 fours VMR), de deux lignes de conditionnement et de deux aires de
                        stockage ;
                      </li>
                      <li className="m-2">
                        les services techniques et de maintenance ;
                      </li>
                      <li className="m-2">
                        le pôle Recherche et Développement.
                      </li>
                    </ul>
                </div>
            </MDBCol>
            <MDBCol lg="5" sm="12">
                <div className="grey lighten-2 p-5 about-section-1-img"
                style={{backgroundImage:"url("+braises+")",backgroundPosition:'center',backgroundSize:'cover'}}>
                </div>
            </MDBCol>
            </MDBRow>
            </section>
            <section className="pt-5 bg-white">
            <MDBRow className="pt-5 d-flex justify-content-center about-data-section-background">
              <MDBCol lg="8" md="10">
                <MDBRow
                className="justify-content-center"
                style={{paddingBottom:'6rem',borderBottom:'1px solid rgba(0,0,0,.1)'}}>
                  <StatCard
                    source={market}
                    number={4000}
                    color={{color:'#000000'}}
                    text="points de vente en France en 2021"
                    duration={2}
                  />
                  <StatCard
                    source={bag}
                    number={3400000}
                    color={{color:'#000000'}}
                    text="sacs vendus par an"
                    duration={3}
                  />
                  <StatCard
                    source={market_share}
                    number={12}
                    suffix="%"
                    color={{color:'#000000'}}
                    text="de part de marché du charbon de bois français"
                  />
                  <StatCard
                    source={land}
                    number={11}
                    suffix=" ha"
                    color={{color:'#000000'}}
                    text="de site industriel"
                  />
                  <StatCard
                    source={r_and_d}
                    number={10}
                    suffix="%"
                    color={{color:'#000000'}}
                    text="du chiffre d’affaires investi chaque année, depuis 2010, en R&D"
                  />
                  <StatCard
                    source={euro}
                    number={14}
                    suffix=" millions"
                    color={{color:'#000000'}}
                    text="d’euros de chiffre d’affaires en 2020"
                    duration={2}
                  />
                  <StatCard
                    source={certificate}
                    number={28}
                    suffix=" ans"
                    color={{color:'#000000'}}
                    text="d’existence dans le Grand Est"
                  />
                  <StatCard
                    source={crowd}
                    number={40}
                    color={{color:'#000000'}}
                    text="collaborateurs motivés"
                  />
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </section>
      </div>
    );
  }
}

export default AboutPage;
