import React from 'react';
import {
    MDBCol,
    MDBRow
} from 'mdbreact';
import ReactGA from 'react-ga';
import './LegalPage.css';
import LegalIconCard from '../components/legalIconCard';
import LegalPhotoCard from '../components/legalPhotoCard';

import bag from './svg/bag.svg';
import market_share from './svg/market_share.svg';
import factory from './svg/factory.svg';
import idea from './svg/idea.svg';
import development from './svg/development.svg';
import certificate from './svg/certificate.svg';
import crowd from './svg/crowd.svg';
import market from './svg/market.svg';
import quote from './svg/quote_og.svg';

import grillades from './img/grillades.jpg';
import repasDehors from './img/repas_dehors_md.jpg';
import ingenieurs from './img/ingenieurs.jpg';
import grillPro from './img/grill_pro.jpg';
import foret from './img/foret.jpg';

class LegalPage extends React.Component {

    scrollToTop = () => window.scrollTo(0, 0);

    componentDidMount(){
        document.title = "Carbo France - Mentions légales";
        this.props.printURL(window.location.pathname);
        ReactGA.pageview(window.location.pathname);
    }

  render() {
    return (
        <div>
          <section className="text-section legal-container">
            <MDBCol lg="6" md="10" className="mx-auto px-5 py-5 mt-5 legal-content">
                <div className="text-left mb-5">
                    <h3 className="weight-600 text-uppercase">Mentions légales</h3>
                    <p className="paragraph text-left">
                    
                    Dénomination sociale ou raison sociale : CARBO France<br></br>
                    Adresse du siège social : Ecurey, 55290 Montiers-sur-Saulx<br></br>
                    Numéro de téléphone et adresse de courrier électronique : 03.29.75.88.03, contact@carbofrance.fr<br></br>
                    Forme juridique de la société : SARL unipersonnelle <br></br>
                    Montant du capital social : 3 340 000€<br></br>
                    Numéro d'inscription au registre du commerce et des sociétés : RCS BAR LE DUC - 393 100 714<br></br>
                    Numéro individuel d'identification fiscale : 39310071400012<br></br>
                    <br></br>
                    Directeur de la publication : Nadège SIMON<br></br>
                    Responsable de la rédaction : Clémence LIEVAL<br></br>
                    Hébergeur : OVH, 2 rue Kellermann, 59100 Roubaix France, 08 203 203 63<br></br>

                    </p>
                    
                </div>
                <div className="text-left mb-5">
                    <h3 className="weight-600 text-uppercase">Contenu et utilisation des informations</h3>
                    <p className="paragraph text-left">
                    
                    L’ensemble de ce site relève de la législation française et internationale sur le droit
                    d’auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés.
                    La reproduction ou représentation, intégrale ou partielle, de ce site sur un support
                    électronique ou tout autre support quel qu'il soit est formellement interdite sauf
                    autorisation expresse du directeur de publication.<br></br><br></br>
                    CARBO France met en œuvre les meilleurs moyens pour assurer l’exactitude des
                    informations diffusées. Toutefois, la responsabilité de CARBO France ne pourrait en
                    aucun cas être engagée en cas d’inexactitude ou caractère incomplet d’une information
                    figurant sur le site.
                    </p>
                </div>

                <div className="text-left">
                    <h3 className="weight-600 text-uppercase">Informatique et liberté</h3>
                    <p className="paragraph text-left">
                    
                    Nous informons les utilisateurs que les données à caractère nominatif
                    recueillies auprès des utilisateurs de ce site par l’intermédiaire des
                    formulaires sont destinées au fonctionnement du site et ne sauraient
                    être transmises à des tiers.<br></br><br></br>
                    Les données personnelles sont conservées au minimum durant toute la
                    durée de la relation commerciale et, le cas échéant, jusqu'à ce que
                    l'internaute fasse une demande de retrait.<br></br><br></br>
                    Pour toute demande concernant le site internet, merci d’utiliser
                    le formulaire de contact.<br></br><br></br>
                    </p>
                </div>

                <div className="text-left">
                    <h3 className="weight-600 text-uppercase">Illustrations et crédit</h3>
                    <p className="paragraph text-left">
                        Illustrations utilisées n'étant pas la propriété de CARBO France :
                    </p>
                    <h5 className="weight-600 text-uppercase text-center my-5">icônes</h5>
                    <MDBRow className="d-flex justify-content-center mt-3">
                        <LegalIconCard
                            link="https://thenounproject.com/icon/1287014/"
                            source={bag}
                            text="Coal par Anton Barbarov"
                        />
                        <LegalIconCard
                            link="https://thenounproject.com/icon/3013244/"
                            source={market_share}
                            text="10 minutes par Evgeniy Kozachenko"
                        />
                        <LegalIconCard
                            link="https://thenounproject.com/ines.simoes.pt.17/"
                            source={factory}
                            text="Factory par Ines Simoes"
                        />
                        <LegalIconCard
                            link="https://thenounproject.com/icon/285000/"
                            source={idea}
                            text="Idea par joe pictos"
                        />
                        <LegalIconCard
                            link="https://thenounproject.com/icon/2799437/"
                            source={development}
                            text="development par Nithinan Tatah"
                        />
                        <LegalIconCard
                            link="https://thenounproject.com/icon/426773/"
                            source={certificate}
                            text="Certificate par Gregor Cresnar"
                        />
                        <LegalIconCard
                            link="https://thenounproject.com/icon/3904922/"
                            source={crowd}
                            text="crowd par Miguel C Balandrano"
                        />
                        <LegalIconCard
                            link="https://thenounproject.com/icon/1945618/"
                            source={market}
                            text="market par vigorn"
                        />
                        <LegalIconCard
                            link="https://thenounproject.com/icon/867767/"
                            source={quote}
                            text="Quote par Montu Yadav"
                        />
                    </MDBRow>
                    <h5 className="weight-600 text-uppercase text-center my-5">photos</h5>
                    <MDBRow className="d-flex justify-content-center mt-3">
                        <MDBCol lg="6" md="12">
                            <LegalPhotoCard
                                link="https://unsplash.com/photos/UC0HZdUitWY"
                                source={grillades}
                                text="photo de Victoria Shes"
                            />
                            <LegalPhotoCard
                                link="https://unsplash.com/photos/SyRlD4s_amw"
                                source={ingenieurs}
                                text="photo de ThisisEngineering RAEng"
                            />
                            <LegalPhotoCard
                                link="https://unsplash.com/photos/y1XXWct5rBo"
                                source={repasDehors}
                                text="photo de Lee Myungseong"
                            />
                        </MDBCol>
                        <MDBCol lg="6" md="12">
                            <LegalPhotoCard
                                link="https://unsplash.com/photos/9sM53e9wTTY"
                                source={grillPro}
                                text="photo de Gabriel Forsberg"
                            />
                            <LegalPhotoCard
                                link="https://unsplash.com/photos/YWbSFHKt110"
                                source={foret}
                                text="photo de Alexandre Brondino"
                            />
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBCol>
          </section>
      </div>
    );
  }
}

export default LegalPage;
