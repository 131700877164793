import React from 'react';
import {
    MDBRow,
    MDBCol
} from 'mdbreact';

const LegalPhotoCard = ({ link, source, text }) => {
  return (
    <>
    <MDBCol size="12" className="mb-5 p-0">
        <MDBCol size="12">
            <a href={link} target="blank">
                <img className="w-100 h-auto" src={source} alt={text}/>
            </a>
        </MDBCol>
        <MDBCol size="12" className="d-flex align-items-center">
            <cite><small className="my-auto">{text}</small></cite>
        </MDBCol>
    </MDBCol>
    </>
  );
};

export default LegalPhotoCard;
