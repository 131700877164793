import React from 'react';
import {
    MDBCol,
    MDBBtn
} from 'mdbreact';

const orderFormInput = ({name, bagsPerPallet, value, text, passedFunction}) => {

    return (
        <>
            <MDBCol md="12" className="d-flex flex-column my-5 orderChild">
                <input
                    value={value}
                    onChange={(e) => passedFunction(e)}
                    type="number"
                    min={0}
                    max={33}
                    step={1}
                    id={"orderValue_"+name}
                    name={name}
                    required
                    className={(value||value===0?"form-control label-hopped":"form-control")}
                />
                <label
                    htmlFor={"orderValue_"+name}
                    className="grey-text weight-700"
                >
                    {text}
                </label>
                <div className="temporary-hint grey-text">
                    1 palette = {bagsPerPallet} sacs
                </div>
                <div className="invalid-feedback">
                    Veuillez sélectionner une quantité de produit
                </div>
            </MDBCol>
        </>
    )
}

export default orderFormInput