import React from 'react';
import {
    MDBAnimation,
    MDBRow,
    MDBCol,
    MDBContainer
} from 'mdbreact';
import ReactGA from 'react-ga';
import './TransitionPage.css';

import ingenieurs from './img/ingenieurs.jpg';
import grillPro from './img/grill_pro.jpg';
import foret from './img/foret.jpg';
import Local from './img/Saudron.jpg';
import quote from './svg/quote.svg';
import cea from './img/LOGO_CEA.png';
import gip from './img/LOGO_GIP_55.jpg';

class TransitionPage extends React.Component {
  scrollToTop = () => window.scrollTo(0, 0);

  componentDidMount(){
    document.title = "Carbo France - Projet";
    this.props.printURL(window.location.pathname);
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
        <div className="overflow-hidden bg-white">
            <MDBCol style={{
          width: '100%',
          height: '50vh',
          overflow: 'hidden',
          marginTop: '74px',}}
          className="mx-0 px-0 d-flex justify-content-center align-items-center">
          <img
          src={ingenieurs}
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%'}}
          >
          </img>
        </MDBCol>
            <section className="text-section">
                <MDBRow>
                    <MDBCol lg="2" md="1" size="6" className="ml-auto">
                        <img className="w-100 h-auto  mb-auto quote-svg-left" src={quote}></img>
                    </MDBCol>
                
                    <MDBCol lg="6" md="10" sm="12">
                        <div className="text-center px-5 bigger-text">
                            <h3 className="weight-600 text-uppercase">Le mot de la gérante</h3>
                            <p className="paragraph">
                            Pour anticiper et préfigurer de nombreuses mutations technologiques et
                            sociétales, CARBO France s’est doté, dès 2010, d’une ingénierie interne
                            en Recherche et Développement, avant d’engager un partenariat avec le
                            CEA Tech de Metz à partir de 2016. Les avancées de nos travaux,
                            soutenus par le GIP Objectif Meuse, nous permettent aujourd’hui
                            d’engager la modernisation globale de notre outil productif dans un
                            environnement industriel répondant aux enjeux de compétitivité économique
                            et de performance environnementale. Cette ambition se traduit concrètement
                            avec le projet de construction d’une nouvelle unité de production à quelques
                            kilomètres de notre site actuel, sur la zone d’activités économiques Parc’Innov. 
                            </p>
                            <p className="text-right weight-700">Nadège SIMON</p>
                            <div className="logo-img-div-sm flex-row justify-content-center">
                              <img className="logo-img" src={cea}></img>
                              <img className="logo-img" src={gip}></img>
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol lg="2" md="1" size="6" className="mr-auto quote-container">
                        <div className="logo-img-div flex-column p-auto align-items-center">
                          <img className="logo-img" src={cea}></img>
                          <img className="logo-img" src={gip}></img>
                        </div>
                        <img className="w-100 h-auto mt-auto quote-svg-right" src={quote}></img>
                    </MDBCol>
                </MDBRow>
            </section>
            <MDBContainer className="py-5">
            </MDBContainer>
            <section className="mb-5 m-md-5">
                <MDBRow className="m-0">
                    <MDBCol className="mr-md-5 transition-bg"
                    style={{backgroundImage:"url("+grillPro+")",backgroundPosition:'center'}}>
                    </MDBCol>
                    <MDBCol md="7" sm="10" className="d-flex justify-content-center blue-grey lighten-5">
                        <div className="text-left bigger-text transition-text-block">
                            <h3 className="weight-600 text-uppercase">Satisfaire les consommateurs, même les plus exigeants</h3>
                            <p>
                            Les capacités de production atteindront 30 000t annuelles, soit ¼ des besoins du
                            marché français.<br></br><br></br>
                            CARBO France commercialisera des produits 100% français, tracés et
                            respectueux des écosystèmes forestiers<br></br><br></br>
                            L'innovation apportée au charbon de bois permettra de garantir la saveur particulière
                            des aliments cuits au barbecue.<br></br><br></br>
                            </p>
                            <hr className="hr-colored-thin black"></hr>
                        </div>
                    </MDBCol>
                </MDBRow>
            </section>
            <section className="mb-5 m-md-5">
                <MDBRow className="m-0">
                    <MDBCol md="7" sm="10" className="d-flex justify-content-center mdb-color darken-1">
                        <div className="text-left text-white bigger-text transition-text-block">
                            <h3 className="weight-600 text-uppercase">des performances améliorées et les ressources naturelles préservées</h3>
                            <p className="paragraph">
                            Le rendement matière sera amélioré de 25% : 4,5 tonnes de bois humide seront nécessaires pour produire une
                            tonne de charbon, contre 6 tonnes actuellement.<br></br><br></br>
                            La démarche volontariste et ambitieuse en matière de sobriété énergétique permettra d’atteindre
                            la neutralité carbone.<br></br><br></br>
                            La conception de la nouvelle usine s’inscrit dans une démarche éco-responsable englobant les
                            enjeux d’intégration paysagère, de limitation de l’imperméabilisation des sols, ainsi que de
                            durabilité et de mutabilité des installations.<br></br><br></br>
                            </p>
                            <hr className="hr-colored-thin white"></hr>
                        </div>
                    </MDBCol>
                    <MDBCol className="ml-md-5 transition-bg"
                    style={{backgroundImage:"url("+foret+")",backgroundPosition:'center'}}>
                    </MDBCol>
                </MDBRow>
            </section>
            <section className="m-sm-0 m-md-5">
                <MDBRow className="m-0">
                <MDBCol className="mr-md-5 transition-bg"
                    style={{backgroundImage:"url("+Local+")"}}>
                    </MDBCol>
                    <MDBCol md="7" sm="10" className="d-flex justify-content-center blue-grey lighten-5">
                        <div className="text-left bigger-text transition-text-block">
                            <h3 className="weight-600 text-uppercase">Un ancrage local qui stimule l’économie circulaire</h3>
                            <p className="paragraph">
                            L’implantation du nouveau site de production est prévue dans le Grand Est, à quelques
                            kilomètres de l’usine actuelle.<br></br><br></br>
                            CARBO France maintient sa contribution à l'économie locale.<br></br><br></br>
                            Le potentiel de récupération d’énergie et de matières liées à la préparation
                            et à la carbonisation du bois sera partagé et utilisé à proximité par
                            des systèmes de production d’électricité et de valorisation de chaleur
                            fatale.<br></br><br></br>
                            </p>
                            <hr className="hr-colored-thin fr-clair"></hr>
                        </div>
                    </MDBCol>
                </MDBRow>
            </section>
        </div>
    );
  }
}

export default TransitionPage;
