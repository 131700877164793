import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBIcon
} from "mdbreact";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption
} from "@reach/combobox";
import { ReCaptcha } from 'react-recaptcha-google';

import Modal from './SmallModal';
import Order from './Order';
import TailgateOption from "../components/tailgateOption";

import counties from '../assets/dpt.json';
import priceList from '../assets/tarif_livraison.json';
import CGV from '../assets/CGV_2021.pdf';

import emailKey from './emailkey';
import emailjs from 'emailjs-com';

class EstimateForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      mailData:{
        user_name: "",
        user_number:" ",
        user_email: "",
        user_phone: "",
        city: "",
        zip:'',
        shipping:0,
        shippingCost:0
      },
      extraCost:0,
      isCompany:false,
      shippingList:'',
      county:"",
      message:"",
      items: [],
      zips:[],
      values: [],
      modal:"error",
      toggle:true
    }
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  };

  componentDidMount() {
    if (this.captcha) {
      console.log("started")
      this.captcha.reset();
    }
  }
  onLoadRecaptcha() {
    if (this.captcha) {
      this.captcha.reset();
    }
  }
  verifyCallback() {
    console.log("access granted");
    this.setState({verified: true});
  }

  submitHandler(event, template){
    event.preventDefault();
    event.target.className += " was-validated";
    const isFilled = this.filledTest();
    (isFilled&&(
        event.target.className = "needs-validation",
        this.clearInputs(),
        this.setState({toggle: false, modal: "success"}),
        this.toggle(),
        emailjs.sendForm(emailKey.SERVICE_ID, emailKey[template], event.target, emailKey.USER_ID)
        .then((result) => {
          console.log("Message envoyé !", result.text);
        },
        (error) => {
          alert("Une erreur a eu lieu, veuillez réessayer", error.text);
        })
    ));
    (!isFilled&&(
      this.setState({toggle: false, modal: "empty"}),
      this.toggle()
    ));
  };
    
  changeHandler = event => {
    let name = event.target.name, value = event.target.value;
    if (name == "user_email"){this.checkEmail()}
    this.setState(({mailData}) => ({mailData: {
      ...mailData,
      [name]: value
    }}));
  };

  changeMessageHandler = event => {
    let name = event.target.name, value = event.target.value;
    this.setState({[name]: value});
  };

    
  regionSelectHandler(e, item){
    var region = item.dep_name+' ('+item.num_dep+')';
    let county = item.num_dep;
    let shippingList = priceList.filter(price => price[0] === county);
    this.setState(({mailData}) => ({mailData: {
      ...mailData,
      city: region
    }}));
    this.setState({county: county, shippingList: shippingList}, () =>
    this.updateShippingCost(this.state.mailData.shipping?this.state.mailData.shipping:0));
  }

  adressSelectHandler(e, item){
    var zip = item.properties.label;
    console.log('zip',zip)
    this.setState(({mailData}) => ({mailData: {
      ...mailData,
      zip: zip
    }}));
  }

  updateShippingCost = (value) => {
    this.setState(({mailData}) => ({mailData: {
      ...mailData,
      shipping: value,
      shippingCost: (value==0?0:(this.state.shippingList?this.state.shippingList[0][(value==33?32:value)]:0))
    }}));
  }

  setExtraCost = (checked, value) => {
    this.setState({extraCost: (checked?value:0)});
  }
  
  regionChangeHandler = event => {
    var value = event.target.value;
    this.setState(({mailData}) => ({mailData: {
      ...mailData,
      city: value
    }}));
    var lowered = event.target.value.toLowerCase();
    var newValue = lowered.split(' ').join('-');
    let list = counties.filter(item => item.dep_name.toLowerCase().includes(newValue));
    let slist = counties.filter(item => item.num_dep.includes(newValue));
    let totalList = [...list.slice(0,5), ...slist.slice(0,5)];
    this.setState({
      items: totalList
    });
  };

  adressChangeHandler = event => {
    var value = event.target.value;
    console.log(this.state.zip);
    this.setState(({mailData}) => ({mailData: {
      ...mailData,
      zip: value
    }}));
    fetch("https://api-adresse.data.gouv.fr/search/?q="+value+"&limit=5")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            zips: result.features
          });
        },
        (error) => {
          this.setState({
            error
          });
        }
      )
  };

  clearInputs(){
      this.setState(({mailData}) => ({mailData: {
        ...mailData,
        user_name: "",
        user_number:"x",
        user_email: "",
        user_phone: "",
        city: "",
        zip:"",
        shipping:0,
        shippingCost:0
      }}));
      this.setState({
        isCompany:false,
        message:"",
        shippingList:'',
        county:"",
        items: [],
        zips:[],
        values: [],
        modal:"error",
        toggle:true});
  };

  filledTest(){
    let count = 0;
    Object.keys(this.state.mailData).map((key) => {
        if(this.state.mailData[key]) {
          if([key]=="user_email") {
            this.checkEmail()?count+=1:count=count;

          }
          else{count +=1;}
        }
      }
    );
    return (count==Object.keys(this.state.mailData).length?true:false);
  };

  checkEmail(){
    let isTrue = false;
    this.state.mailData.user_email.includes('@')?
    (document.getElementById('formUserEmail').classList.remove('is-invalid'),isTrue = true)
    :(document.getElementById('formUserEmail').classList.add('is-invalid'), isTrue = false);
    return isTrue;
  }

  toggle(){
      this.setState({toggle: !this.state.toggle});
  };
  setIsCompany = () => {
    this.setState({isCompany: !this.state.isCompany});
  };

  resetForm = () => {
    this.setState({toggle: true, modal: "error"}, () => console.log("Form has been reset"));
  };

      render() {
        return (
          <MDBContainer className="pt-3">
                {!this.state.toggle&&this.state.modal=="success"&&
                <Modal initialModalState={true}
                title="Message envoyé"
                text="Nous vous remercions pour votre demande, nos équipes commerciales vous recontactent rapidement pour valider et finaliser notre proposition tarifaire" color="success" resetForm={this.resetForm}/>
                }
                {!this.state.toggle&&this.state.modal=="error"&&
                <Modal initialModalState={true}
                title="Une erreur est survenue"
                text="Veuillez réessayer" color="danger" resetForm={this.resetForm}/>
                }
                {!this.state.toggle&&this.state.modal=="empty"&&
                <Modal initialModalState={true}
                title="Formulaire incomplet"
                text="Veuillez renseigner les champs marqués en rouge" color="warning" resetForm={this.resetForm}/>
                }
            <form
              className="needs-validation"
              onSubmit={(e) => {
                this.submitHandler(e, 'PRO_TEMPLATE_ID')}}
              noValidate
            >
              <MDBCol xl="6" lg="8" sm="10" className="mx-auto mb-5">
              <MDBRow className="bg-white  z-depth-1">
                <h4 className="weight-600 text-uppercase mx-auto my-3">vos coordonnées</h4>
                <MDBCol md="12" className="my-4 testChildDiv">
                  <input
                    value={this.state.mailData.user_name}
                    name="user_name"
                    onChange={this.changeHandler}
                    type="text"
                    id="formUserName"
                    className={(this.state.mailData.user_name?"form-control label-hopped":"form-control")}
                    required
                  />
                  <label
                    htmlFor="formUserName"
                    className="grey-text"
                  >
                    Nom ou raison sociale
                  </label>
                  {/* <div className="valid-feedback">Looks good!</div> */}
                  <div className="invalid-feedback">
                    Veuillez entrer votre nom ou raison sociale
                  </div>
                </MDBCol>
                <MDBCol md="12" className="d-flex flex-column my-3">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="isCompany" onChange={() => this.setIsCompany()}/>
                        <label class="custom-control-label" for="isCompany">Vous êtes une entreprise</label>
                    </div>
                </MDBCol>
                {this.state.isCompany&&
                <MDBCol md="12" className="my-4 testChildDiv">
                  <input
                    value={this.state.mailData.user_number}
                    onChange={this.changeHandler}
                    type="text"
                    id="tvaOrSiret"
                    className={(this.state.mailData.user_number?"form-control label-hopped":"form-control")}
                    name="user_number"
                    required
                  />
                  <label
                    htmlFor="tvaOrSiret"
                    className="grey-text"
                  >
                    Numéro de TVA ou SIRET
                  </label>
                  <div className="invalid-feedback">
                    Veuillez entrer un numéro de TVA ou SIRET
                  </div>
                </MDBCol>
                }
                <MDBCol md="12" className="my-4 testChildDiv">
                  <input
                    value={this.state.mailData.user_email}
                    onChange={this.changeHandler}
                    type="text"
                    id="formUserEmail"
                    className={(this.state.mailData.user_email?"form-control label-hopped":"form-control")}
                    name="user_email"
                    required
                  />
                  <label
                    htmlFor="formUserEmail"
                    className="grey-text"
                  >
                    Email
                  </label>
                  <div className="invalid-feedback">
                    Veuillez entrer une adresse mail valide
                  </div>
                </MDBCol>
                <MDBCol md="12" className="my-4 testChildDiv">
                  <input
                    value={this.state.mailData.user_phone}
                    onChange={this.changeHandler}
                    type="text"
                    id="formUserPhone"
                    className={(this.state.mailData.user_phone?"form-control label-hopped":"form-control")}
                    name="user_phone"
                    required
                  />
                  <label
                    htmlFor="formUserPhone"
                    className="grey-text"
                  >
                    Téléphone
                  </label>
                  <div className="invalid-feedback">
                    Veuillez entrer un numéro de téléphone
                  </div>
                </MDBCol>
                <MDBCol md="12" className="my-4 testChildDiv">
                  <Combobox aria-label="Cities" openOnFocus={true} onSelect={(item) => {}}>
                    <ComboboxInput
                      value={this.state.mailData.city}
                      id="formCity"
                      autoComplete="off"
                      onChange={this.regionChangeHandler}
                      className={(this.state.mailData.city?"form-control city-search-input label-hopped":"form-control city-search-input")}
                      name="city"
                      style={{ width: '100%' }}
                      required
                    />
                    <ComboboxPopover>
                    <ComboboxList>
                      {this.state.items[0] &&
                        this.state.items.map(item =>
                        <ComboboxOption onClick={(e) => {this.regionSelectHandler(e, item)}}
                          value={item.dep_name+" ("+item.num_dep+")"}> 
                        </ComboboxOption>)
                      }
                      {!this.state.items[0] &&
                        <ComboboxOption value="null">
                          Aucun résultat trouvé
                        </ComboboxOption>
                      }
                    </ComboboxList>
                    </ComboboxPopover>
                    <label
                      htmlFor="formCity"
                      className="grey-text"
                    >
                      Département de livraison
                    </label>
                    <div className="invalid-feedback">
                      Veuillez entrer un nom de ville
                    </div>
                  </Combobox>
                </MDBCol>
                <MDBCol md="12" className="my-4 testChildDiv">
                  <Combobox aria-label="Zip" openOnFocus={true} onSelect={(item) => {}}>
                    <ComboboxInput
                      value={this.state.mailData.zip}
                      id="formZip"
                      autoComplete="off"
                      onChange={this.adressChangeHandler}
                      className={(this.state.mailData.zip?"form-control city-search-input label-hopped":"form-control city-search-input")}
                      name="zip"
                      style={{ width: '100%' }}
                      required
                    />
                    <ComboboxPopover>
                      <ComboboxList>
                        {this.state.zips[0] &&
                          this.state.zips.map(item =>
                          <ComboboxOption onClick={(e) => {this.adressSelectHandler(e, item)}}
                            value={item.properties.label}> 
                          </ComboboxOption>)
                        }
                        {!this.state.zips[0] &&
                          <ComboboxOption value="null">
                            Aucun résultat trouvé
                          </ComboboxOption>
                        }
                      </ComboboxList>
                    </ComboboxPopover>
                    <label
                      htmlFor="formZip"
                      className="grey-text"
                    >
                      Adresse de livraison
                    </label>
                    <div className="invalid-feedback">
                      Veuillez entrer une adresse
                    </div>
                  </Combobox>
                </MDBCol>
                </MDBRow>
                <MDBRow className="d-flex justify-content-center mt-3 bg-white z-depth-1">
                <Order updateShippingCost={this.updateShippingCost} shippingCost={this.state.mailData.shippingCost+this.state.extraCost}/>
                <div className="my-2">
                  <a href={CGV} target="blank">Conditions générales de vente</a>
                </div>
                <TailgateOption setExtraCost={this.setExtraCost} quantity={this.state.mailData.shipping}/>
                <input name="total_qty" type="hidden" value={this.state.mailData.shipping}></input>
                </MDBRow>
                <MDBRow className="mt-3 bg-white z-depth-1">
                <h4 className="weight-600 text-uppercase mx-auto my-3">ajouter un message</h4>
                <MDBCol md="12" className="my-4 testTextarea">
                  <textarea
                    value={this.state.message}
                    onChange={this.changeMessageHandler}
                    id="formMessage"
                    className={(this.state.message?"form-control label-hopped":"form-control")}
                    name="message"
                  />
                  <label
                    htmlFor="formMessage"
                    className="grey-text"
                  >
                    Précision ou demande supplémentaire
                  </label>
                  <div className="invalid-feedback">
                    Veuillez écrire un message
                  </div>
                </MDBCol>
              </MDBRow>
              {!this.state.verified&&
              <MDBRow className="d-flex justify-content-center m-2 mt-5">
                <ReCaptcha
                    ref={(el) => {this.captcha = el;}}
                    size="normal"
                    data-theme="dark"            
                    render="explicit"
                    sitekey={emailKey.CAPTCHA_SITE_KEY}
                    onloadCallback={this.onLoadRecaptcha}
                    verifyCallback={this.verifyCallback}
                />
              </MDBRow>
              }
              {this.state.verified&&
              <MDBRow className="d-flex justify-content-center m-2 mt-5">
                <MDBBtn color="primary" type="submit" className="msgBtn">
                  Envoyer
                  <MDBIcon far icon="paper-plane" className="ml-1" />
                </MDBBtn>
              </MDBRow>
              }
              </MDBCol>
            </form>
          </MDBContainer>
        );
      }
    }
export default EstimateForm;
