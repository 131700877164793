import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LandingPage from './pages/LandingPage';
import ProductsPage from './pages/ProductsPage';
import TransitionPage from './pages/TransitionPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import LegalPage from './pages/LegalPage';

import NotFoundPage from './pages/NotFoundPage';



class Routes extends React.Component {
  render() {
    return (
      <Switch>
        {/* <Route exact path='/' component={LandingPage} /> */}
        <Route exact path='/' render={(props) => (<LandingPage {...props} printURL={this.props.printURL}/>)} />
        <Route exact path='/notre-projet' render={(props) => (<TransitionPage {...props} printURL={this.props.printURL}/>)} />
        <Route exact path='/nos-produits' render={(props) => (<ProductsPage {...props} printURL={this.props.printURL}/>)} />
        <Route exact path='/qui-sommes-nous' render={(props) => (<AboutPage {...props} printURL={this.props.printURL}/>)} />
        <Route exact path='/nous-contacter' render={(props) => (<ContactPage {...props} printURL={this.props.printURL}/>)} />
        <Route exact path='/mentions-legales' render={(props) => (<LegalPage {...props} printURL={this.props.printURL}/>)} />
        
        <Route component={NotFoundPage}/>
      </Switch>
    );
  }
}

export default Routes;
