import React from 'react';
import {
  MDBView,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBMask,
  MDBAnimation,
  MDBBtn
} from 'mdbreact';
import './LandingPage.css';
import ReactGA from 'react-ga';

class LandingPage extends React.Component {
  scrollToTop = () => window.scrollTo(0, 0);

  componentDidMount(){
    document.title = "Carbo France";
    this.props.printURL(window.location.pathname);
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
        <div id="apppage">
        <MDBView>
          <MDBContainer>
            <MDBMask className="d-flex justify-content-center align-items-center gradient">
              <MDBContainer>
                <MDBRow center>
                  <MDBCol
                    className="white-text d-flex justify-content-center text-center mt-xl-5 mb-5"
                  >
                    <MDBAnimation type="fadeInDown" delay=".3s">
                      <h1 className="h1-responsive weight-800 mt-sm-5 text-uppercase important-text">
                        carbo france
                      </h1>
                      <h5 className="mb-4 weight-200 important-pr">
                        Producteur de charbon végétal en Grand Est
                      </h5>
                      <MDBBtn href="/qui-sommes-nous" outline color="white" className="rounded font-weight-bold">En savoir plus</MDBBtn>
                    </MDBAnimation>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBMask>
          </MDBContainer>
          </MDBView>
        </div>
    );
  }
}

export default LandingPage;
