import React from 'react';
import {
    MDBAnimation,
    MDBContainer,
    MDBRow,
    MDBCol
} from 'mdbreact';
import ReactGA from 'react-ga';
import './ProductPage.css';

import ProductCard from '../components/productCard';

import grillades from './img/grillades.jpg';
import repasDehors from './img/repas_dehors_md.jpg';

import powderBag from './img/sac_poudre.jpg';
import BBQBag from './img/sac_bbq.jpeg';
import RestoBag from './img/sac_resto.jpeg';
import innovationBag from './img/sac_innovation.jpg';
import productBBQ from './img/produit_bbq.jpg';
import productResto from './img/produit_resto.jpg';
import productPowder from './img/produit_poudre.jpg';

class ProductsPage extends React.Component {

  scrollToTop = () => window.scrollTo(0, 0);

  componentDidMount(){
    document.title = "Carbo France - Produits";
    this.props.printURL(window.location.pathname);
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
        <div className="overflow-hidden">
            <MDBContainer style={{minHeight: '50vh', position:'relative', marginTop:'74px'}}
            className="header-margin-md d-flex justify-content-center align-items-center">
                <MDBRow center>
                    <MDBCol
                    className="d-flex justify-content-center text-center"
                    >
                    <MDBAnimation type="fadeInDown" delay=".3s">
                        <h1 className="weight-800 important-text text-uppercase">
                        un produit adapté
                        </h1>
                        <h5 className="mb-4 weight-600 important-pr">
                        à chacun de vos besoins
                        </h5>
                    </MDBAnimation>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <MDBContainer className="w-100" style={{maxWidth:'100%'}}>
                <MDBRow className="product-first-section-row">
                    <MDBCol className="d-flex justify-content-center align-items-center bg-white">
                        <div className="p-5 bigger-text weight-600">
                            <p>CARBO France est désireux de partager des moments simples et conviviaux
                            avec tous les amoureux de cuisine en plein air. Pique-niques, repas en
                            famille ou entre amis …</p>
                            <p>Nous mettons tout en œuvre pour vous faciliter
                            le partage de bons moments autour d’un barbecue, dans le respect de
                            l’environnement.</p>
                        </div>
                    </MDBCol>
                    <MDBCol sm="12" md="6" className="banner-section-img"
                        style={{backgroundImage:"url("+grillades+")"}}>
                        <div></div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="product-first-section-row-2">
                    <MDBCol sm="12" md="6" className="simple-bg d-flex justify-content-center align-items-center product-first-section-text">
                        <div className="p-5 text-white text-center text-uppercase">
                            <h1 className="weight-200">Partageons</h1>
                            <h1 className="weight-200">des moments</h1>
                            <h1 className="weight-900 outline-black">simples</h1>
                        </div>
                    </MDBCol>
                    <MDBCol sm="12" md="6" className="product-first-section-img"
                        style={{backgroundImage:"url("+repasDehors+")"}}>
                        <div></div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>

            <MDBContainer className="w-100 my-0 bg-white" style={{maxWidth:'100%'}}>
                <MDBCol className="d-flex justify-content-center align-items-center">
                    <h1 className="py-5 weight-700 text-uppercase">nos produits</h1>
                </MDBCol>
                <MDBRow>

                    <ProductCard
                    source1={powderBag}
                    source2={productPowder}
                    name="Poudres"
                    text={
                        ["Les poudres de charbon de bois sont utilisées en complément"+
                        " de l’alimentation animale (carbofeed), en amendement des sols"+
                        " (biochar) et à destination de l’industrie (charbon actif)"]
                    }
                    color1="#00004444"
                    color2="#00444444"
                    />

                    <ProductCard
                    source1={BBQBag}
                    source2={productBBQ}
                    name="standard"
                    text={
                        ["Le charbon de bois de qualité « standard » contient des morceaux de 20 à 80 mm",
                        "Idéal pour la cuisson : saucisses, merguez, lard, poisson, viande blanche, légumes..."]
                    }
                    dims="Dimensions du sac présenté : 30x60x14 (en cm)"
                    color1="#00004444"
                    color2="#00444444"
                    />

                    <ProductCard
                    source1={RestoBag}
                    source2={productResto}
                    name="restaurant"
                    text={
                        ["Le charbon de bois de qualité « restaurant »"+
                        "contient des morceaux de 80 à 120 mm."+
                        "Il est utilisé par les professionnels et les vrais amateurs de grillades",
                        "Idéal pour la cuisson : entrecôtes, faux-filet, côtelettes, agneau..."]
                    }
                    dims="Dimensions du sac présenté : 39x98x16 (en cm)"
                    color1="#00004444"
                    color2="#00444444"
                    />

                    <ProductCard
                    source1={innovationBag}
                    source2={innovationBag}
                    name="innovation bientôt disponible"
                    text={
                        [
                        // "Un savoir-faire unique en France et une innovation"+
                        // "retentissante à venir",
                        "Le charbon de bois sera moins friable",
                        "La granulométrie du charbon de bois sera régulière"+
                        " et plus importante qu’actuellement",
                        "L’allumage du charbon de bois sera plus rapide",
                        "La saveur naturelle de bois sera plus présente"
                    ]
                    }
                    color1="#00004444"
                    color2="#00444444"
                    />
                </MDBRow>
            </MDBContainer>

            <div style={{height:"100px", background:'#ffffff'}}></div>

            <section className="eco-banner">
                <MDBCol lg="6" md="8" sm="12" className="mx-auto p-0 ">
                    <div className="text-center bigger-text py-5 bg-white">
                        <h3 className="col-10 mx-auto weight-600 text-uppercase text-left-md">L’éco-responsabilité, une valeur qui nous tient à cœur </h3>
                        <hr style={{borderTop: '1px solid #444444', width:'30%'}}></hr>
                        <p className="eco-paragraph col-10 mx-auto">
                        Engagés dans une démarche d’approvisionnement responsable, nous travaillons en proximité étroite
                        avec une centaine des scieries implantées dans le quart nord-est de la France. La production de
                        charbon de bois s’appuie uniquement sur la valorisation des produits connexes de la première
                        transformation du bois (chutes courtes, dosses) et de la valorisation du bois de trituration
                        (rondins) issu de forêts avoisinantes et gérées durablement par l’Office National des Forêts.
                        Ainsi, notre matière première est essentiellement composée de chêne, de hêtre et de charme ;
                        elle ne subit aucun traitement et ne génère aucun abatage d’arbre. 
                        <br></br>
 La certification PEFC (PEFC/10-31-986) de CARBO France prouve, depuis de nombreuses années, les engagements de l’entreprise en matière de gestion durable des forêts.<br></br>
                        Cet ancrage territorial et les nombreux partenariats noués localement ont été mis à l’honneur
                        à la cérémonie des Ailes de Cristal 2019, récompensant les
                        leaders de l’économie du Grand Est, remporté par CARBO France.
                        </p>
                    </div>
                </MDBCol>
            </section>
        </div>
    );
  }
}

export default ProductsPage;
