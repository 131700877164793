import React from 'react';
import {
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn
} from 'mdbreact';

class SmallModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: props.initialModalState
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
    this.props.resetForm();
  }

  render() {
    return (
      <div>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="sm" centered>
          <MDBModalHeader toggle={this.toggle} 
          style={{borderBottom:'none'}}
          className={"text-white bg-"+this.props.color}
          >
            {this.props.title}
          </MDBModalHeader>
          <MDBModalBody>
          {this.props.text}
          </MDBModalBody>
          <MDBModalFooter className="d-flex justify-content-center" style={{borderTop:'none'}}>
            <MDBBtn color={this.props.color} onClick={this.toggle} className="rounded">d'accord</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </div>
    );
  }
}

export default SmallModal;
