import React, {useState} from "react";
import {
  MDBCol,
  MDBRow
} from 'mdbreact';
import PropTypes from "prop-types";
import "./toggleSwitch.css";

const ToggleSwitch = ({onChange}) => {
  const [selected, setSelected] = useState(false);

  function handleClick() {
    setSelected(!selected);
    onChange();
  }

  return (
    <>
    {/* <div class="toggleWrapper weight-600">
      <input type="checkbox" class="dn" id="dn" onChange={() => onChange()}/>
      <label for="dn" class="toggle">
        <span class="toggle__handler">
        </span>
      </label>
    </div> */}
      <MDBCol xl="6" lg="8" md="10" sm="12" className="d-flex mx-auto text-center text-uppercase">
        <MDBCol size="6" className={"rounded-left d-flex align-items-center justify-content-center py-2 new-toggle "+(selected?"":"selected")}
        onClick={() => handleClick()}>
          devis estimatif
        </MDBCol>
        <MDBCol size="6" className={"rounded-right d-flex align-items-center justify-content-center py-2 new-toggle "+(selected?"selected":"")}
        onClick={() => handleClick()}>
          envoyer un message
        </MDBCol>
      </MDBCol>
    </>
  );
};

ToggleSwitch.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default ToggleSwitch;
