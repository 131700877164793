import React from 'react';
import {
    MDBRow,
    MDBCol
} from 'mdbreact';

const ProductCard = ({ source1, source2, name, text, dims, color1, color2}) => {
  return (
    <>
    <MDBCol lg="3" md="6" sm="12" className="first-section-img product-card"
    style={{height: '450px',
    backgroundImage: 'url('+source1+')'
    }}
    name={name}
    >
        <div className="overlay text-white"
        style={{
            backgroundImage: 'linear-gradient('+color1+','+color2+'), url('+source2+')'
        }}
        name="product1">
            <MDBCol className="product-card-text align-items-center"
            name="product1">
                <h1 className="text-uppercase weight-700">{name}</h1>
                <hr color="white" style={{width:'50%', height:'3px'}} className="ml-0"></hr>
                {text.map(element => {
                    return (<h6 className="weight-600">{element}</h6>)
                })}
                <h6 className="mt-5 skew weight-200">{dims}</h6>
            </MDBCol>
        </div>
    </MDBCol>
    </>
  );
};

export default ProductCard;
