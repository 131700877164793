import React from 'react';
import {
    MDBRow,
    MDBCol
} from 'mdbreact';

const LegalIconCard = ({ link, source, text }) => {
  return (
    <>
    <MDBCol lg="6" md="10">
        <MDBRow>
            <MDBCol size="3">
                <a href={link} target="blank">
                    <img className="legal-icon" src={source} alt={text}/>
                </a>
            </MDBCol>
            <MDBCol size="9" className="d-flex align-items-center">
                <span className="my-auto">{text}</span>
            </MDBCol>
        </MDBRow>
    </MDBCol>
    </>
  );
};

export default LegalIconCard;
