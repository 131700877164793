import React from 'react';
import {
  MDBRow,
  MDBCol
} from 'mdbreact';
import OrderFormInput from '../components/orderFormInput';
import priceList from '../assets/tarif_sac.json';

class Test extends React.Component {
  constructor() {
    super();
    this.state = {
    total: 0,
    qty:0,
    numbers: {
      bbq_kg: 0,
      bbq_l: 0,
      resto_kg: 0,
      resto_l: 0
    },
    orders: {
      bbq_kg: priceList[0][1]*36,
      bbq_l: priceList[1][1]*105,
      resto_kg: priceList[2][1]*36,
      resto_l: priceList[3][1]*66
    }
    };
  }

  componentDidMount() {
    this.setState({ total: this.calculateTotal(this.state.numbers) });
  }

  calculateTotal = (numbers) => {
    let finalValue = 0;
    let quantity = 0;
    Object.entries(numbers).map(([key, value]) => {
      if (value != "") {
        quantity += value;
        finalValue += (value*this.state.orders[key]);
      }
    });
    this.setState({qty:quantity}, () => this.props.updateShippingCost(quantity));
    return finalValue;
  }

  handleTotal = (e) => {
    const { value, name } = e.target;
    const parsedValue = value === "" ? "" : parseFloat(value);
    let x = parsedValue;
    parsedValue>33?x=33:(parsedValue<0?x=0:x=parsedValue);
    this.setState((prevState) => {
      const updatedNumbers = {
        ...prevState.numbers,
        [name]: x
      };
      const newTotal = this.calculateTotal(updatedNumbers);
      return {
        numbers: updatedNumbers,
        total: newTotal
      }
    })
  }

  numberWithSpaces = (x) => {
    let y = Math.round(x * 100) / 100;
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  roundUp = (x) => {
    return Math.round(x * 100) / 100;
  }

  render() {
    return (
      <>
        <h4 className="weight-600 text-uppercase mx-auto mt-3 mb-5">votre besoin</h4>
        <MDBRow className="d-flex justify-content-center w-100">
        <MDBCol md="6" sm="12" className="border-right-md">
        <h6 className="text-center text-dark text-uppercase weight-600">qualité standard</h6>
          <OrderFormInput
            name="bbq_kg"
            bagsPerPallet={36}
            value={this.state.numbers.bbq_kg}
            text="STANDARD 10kg"
            passedFunction={this.handleTotal}/>
          <OrderFormInput
            name="bbq_l"
            bagsPerPallet={105}
            value={this.state.numbers.bbq_l}
            text="STANDARD 20L"
            passedFunction={this.handleTotal}/>
          </MDBCol>
          <MDBCol md="6" sm="12">
          <h6 className="text-center text-dark text-uppercase weight-600">qualité restaurant</h6>
          <OrderFormInput
            name="resto_kg"
            bagsPerPallet={36}
            value={this.state.numbers.resto_kg}
            text="RESTAURANT 10kg"
            passedFunction={this.handleTotal}/>
          <OrderFormInput
            name="resto_l"
            bagsPerPallet={66}
            value={this.state.numbers.resto_l}
            text="RESTAURANT 30L"
            passedFunction={this.handleTotal}/>
        </MDBCol>
        </MDBRow>
        <MDBCol size="12" style={{borderTop:'1px solid #bbbbbb'}}>
        <div id="total" className="mt-2">
          Total estimé :<br/>
          - {this.numberWithSpaces(this.state.total+this.props.shippingCost)}€ HT, soit {this.numberWithSpaces((this.state.total+this.props.shippingCost)*1.2)}€ TTC (TVA 20%)<br/>
          - Frais de livraison inclus : {this.numberWithSpaces(this.props.shippingCost)} € HT
        </div>
        </MDBCol>
        <input name="product" type="hidden" value={this.numberWithSpaces(this.state.total)}></input>
        <input name="total" type="hidden" value={this.numberWithSpaces(this.state.total+this.props.shippingCost)}></input>
        <input name="totalTVA" type="hidden" value={this.numberWithSpaces((this.state.total+this.props.shippingCost)*1.2)}></input>
        <input name="shippingCost" type="hidden" value={this.numberWithSpaces(this.props.shippingCost)}></input>
      </>
    );
  }
}

export default Test;