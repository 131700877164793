import React, { Component } from 'react';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBFooter,
  MDBNavLink,
  MDBContainer,
  MDBRow
} from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import BrandLogoSVG from './assets/LOGO_CARBO_FRANCE.png';
import Routes from './Routes';
import './App.css';

class App extends Component {
  state = {
    collapseID: '',
    isTransparent: false
  };

  componentDidMount(){
    ReactGA.initialize('G-8ZED3VK221');
  };
  
  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));

  closeCollapse = collID => () => {
    const { collapseID } = this.state;
    window.scrollTo(0, 0);
    collapseID === collID && this.setState({ collapseID: '' });
  };

  printURL = (url) => {
    console.log(url);
    url=='/'?this.setState({isTransparent:true},
        () => console.log(this.state.isTransparent))
      :this.setState({isTransparent:false},
        () => console.log(this.state.isTransparent));
  };

  render() {
    const overlay = (
      <div
        id='sidenav-overlay'
        style={{ backgroundColor: 'transparent' }}
        onClick={this.toggleCollapse('mainNavbarCollapse')}
      />
    );

    const { collapseID } = this.state;
    const url = window.location.pathname;

    return (
      <Router>
        <div className='flyout blue-grey lighten-5'>
          <MDBNavbar color='mdb-color darken-4' dark expand='lg' fixed='top' scrolling transparent={this.state.isTransparent}
          className={"z-depth-0 "+(this.state.isTransparent?null:"top-nav-collapse")}>
            <MDBNavbarBrand href='/' className='py-0 font-weight-bold'>
              <img src={BrandLogoSVG}></img>
            </MDBNavbarBrand>
            <MDBNavbarToggler
              onClick={this.toggleCollapse('mainNavbarCollapse')}
            />
            <MDBCollapse id='mainNavbarCollapse' isOpen={collapseID} navbar>
              <MDBNavbarNav right className="pr-3 weight-700 letter-spacing-1">
                <MDBNavItem>
                  <MDBNavLink
                    exact
                    to='/'
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    className="mx-1"
                  >
                    Accueil
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    exact
                    to='/qui-sommes-nous'
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    className="mx-1"
                  >
                    Qui sommes-nous ?
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    exact
                    to='/nos-produits'
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    className="mx-1"
                  >
                    Nos produits
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    exact
                    to='/notre-projet'
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    className="mx-1"
                  >
                    Notre projet
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    exact
                    to='/nous-contacter'
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    className="mx-1"
                  >
                    Nous contacter
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBNavbar>
          {collapseID && overlay}
          <Routes printURL={this.printURL}/>
          <MDBFooter color="elegant-color-dark" className="font-small">
            <div className="footer-copyright text-center py-3">
              <MDBRow className="d-flex justify-content-center">
                <a href='/mentions-legales' className="my-3">Mentions légales</a>
              </MDBRow>
              <MDBContainer fluid>
                &copy; {new Date().getFullYear()} Copyright: <a href="#"> Carbo France </a>
              </MDBContainer>
            </div>
          </MDBFooter>
        </div>
      </Router>
    );
  }
}

export default App;
