import React from 'react';
import {
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBContainer
} from 'mdbreact';

import './NotFoundPage.css';

class NotFoundPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true
        };
    }

    scrollToTop = () => window.scrollTo(0, 0);

  render() {
    return (
      <div className="overflow-hidden" style={{marginTop:'74px'}} id="notFoundPage">
        <MDBRow className="d-flex align-items-center justify-content-center">
            <div className="notFound-container">
                <h1 className="weight-900 mb-0">
                erreur 404
                </h1>
                <h3 className="mb-4 weight-400">
                page introuvable
                </h3>
                <MDBBtn href="/" outline color="dark" className="rounded m-0 weight-800 bgwhite">page d'accueil</MDBBtn>
            </div>
        </MDBRow>
      </div>
    );
  }
}

export default NotFoundPage;
